import { useEffect, useState } from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { apiservice } from "../service/service";
const { width } = Dimensions.get("screen");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
  },
  menu: {
    width: width * 0.16,
    backgroundColor: "#fff",
    height: "100%",
  },
  maincontainer: {
    width: width * 0.84,
    backgroundColor: "#f5f5f9",
    padding: 25,
    height: "100%",
  },
  textInput: {
    height: 40,
    width:
      window.innerWidth > 500
        ? window.innerWidth * 0.2
        : window.innerWidth * 0.6,
    backgroundColor: "#fff",
    outlineColor: "transparent",
    outlineStyle: "none",
    borderWidth: 1,
    borderColor: "#707070",
  },
  textmenu: {
    fontFamily: "Prompt",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070",
  },
});

export const MainContainer = ({ onClick }) => {
  const [link, setlink] = useState("");

  async function ApiCall() {
    const res = await apiservice({
      path: "/mobile/url/create",
      method: "post",
      body: {
        img: "",
        img_list: [],
        link,
        status: true,
      },
    });

    if (res.status == 200) {
      trunkcallAPI();
      onClick();
    }
  }

  useEffect(() => {
    trunkcallAPI();
  }, []);

  const trunkcallAPI = async () => {
    const res = await apiservice({
      path: "/mobile/url/getall",
      method: "get",
    });

    if (res.status == 200) {
      setlink(res?.data?.data?.link);
    }
  };

  return (
    <View style={styles.maincontainer}>
      <link
        href="https://fonts.googleapis.com/css2?family=Prompt&display=swap"
        rel="stylesheet"
      />
      <Text style={{ fontFamily: "Prompt", fontSize: 32, fontWeight: "bold" }}>
        <Text style={{ fontFamily: "Prompt", fontSize: 30, color: "#ccc" }}>
          Web view /
        </Text>{" "}
        URL
      </Text>
      <View
        style={{
          flexDirection: "row",
          marginTop: 35,
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: 22,
          width: "40%",
        }}
      >
        <TextInput
          onChangeText={setlink}
          defaultValue={link}
          style={[
            styles.textInput,
            {
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              paddingHorizontal: 10,
              fontFamily: "Prompt",
              borderRightWidth: 0,
              borderColor: "#d9d9d9",
            },
          ]}
        />
        <TouchableOpacity
          onPress={() => {
            ApiCall();
          }}
          style={[
            styles.textInput,
            {
              alignItems: "center",
              justifyContent: "center",
              width: window.innerWidth * 0.07,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              borderColor: "#df1f26",
            },
          ]}
        >
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: 16,
              fontWeight: "bold",
              color: "#df1f26",
            }}
          >
            Apply
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
