import moment from "moment";
import { useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { apiservice } from "../service/service";
const { width } = Dimensions.get("screen");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
  },
  menu: {
    width: width * 0.16,
    backgroundColor: "#fff",
    height: "100%",
  },
  maincontainer: {
    width: width * 0.84,
    backgroundColor: "#f5f5f9",
    padding: 25,
    height: "100%",
  },
  textInput: {
    height: 80,
    width:
      window.innerWidth > 500
        ? window.innerWidth * 0.2
        : window.innerWidth * 0.6,
    backgroundColor: "#fff",
    outlineColor: "transparent",
    outlineStyle: "none",
    borderWidth: 0.5,
    borderColor: "#707070",
  },
  textmenu: {
    fontFamily: "Prompt",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070",
  },
});

export const NotiDetail = ({ detail, onClick }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    trunkcallAPI();
  }, []);

  const trunkcallAPI = async () => {
    const res = await apiservice({
      path: "/mobile/getuser",
      method: "get",
    });

    if (res.status == 200) {
      setList(res?.data);
    }
  };
  return (
    <View style={styles.maincontainer}>
      <link
        href="https://fonts.googleapis.com/css2?family=Prompt&display=swap"
        rel="stylesheet"
      />

      <Text style={{ fontFamily: "Prompt", fontSize: 32, fontWeight: "bold" }}>
        <Text style={{ fontFamily: "Prompt", fontSize: 30, color: "#ccc" }}>
          Web view / Notification /
        </Text>{" "}
        Notification Detail
      </Text>
      <View style={{ marginTop: 35 }}>
        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "bold",
            color: "#707070",
          }}
        >
          Message
        </Text>
        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "normal",
            color: "#707070",
          }}
        >
          {detail?.info?.message}
        </Text>
        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "bold",
            color: "#707070",
            marginTop: 15,
          }}
        >
          Date{" "}
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: 14,
              fontWeight: "normal",
              color: "#707070",
            }}
          >
            {moment(detail?.createdAt).format("DD/MM/YYYY")}
          </Text>
        </Text>

        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "bold",
            color: "#707070",
            marginBottom: 30,
          }}
        >
          Receiver{" "}
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: 14,
              fontWeight: "normal",
              color: "#707070",
            }}
          >
            {detail?.info?.item?.length || 0}
          </Text>
        </Text>

        <FlatList
          data={list?.filter(
            (e) =>
              detail?.info?.item?.filter((el) => el == e?.detail)?.length > 0
          )}
          ListHeaderComponent={
            <View
              style={{
                width: width * 0.8,
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                backgroundColor: "#DBDBDB",
              }}
            >
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                No.
              </Text>
              <Text
                style={{
                  width: "25%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                UID
              </Text>
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                OS
              </Text>
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Read
              </Text>
              <Text
                style={{
                  width: "25%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Location
              </Text>
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                View
              </Text>
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Detail
              </Text>
            </View>
          }
          renderItem={({ item, index }) => {
            console.log(item?.info?.location?.coords?.latitude);
            console.log(item?.info?.location?.coords?.longitude);
            return (
              <View
                style={{
                  width: width * 0.8,
                  flexDirection: "row",
                  height: 40,
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
              >
                <Text
                  style={{
                    width: "10%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {index + 1}
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {item?.detail}
                </Text>
                <Text
                  style={{
                    width: "10%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {item?.info?.OS}
                </Text>
                <Text
                  style={{
                    width: "10%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {item?.info?.status ? "Read" : "Not Read"}
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {"lat : "}
                  {item?.info?.location?.coords?.latitude || "-"}
                  {" lng : "}
                  {item?.info?.location?.coords?.longitude || "- "}
                </Text>

                <TouchableOpacity
                  disabled={!item?.info?.location?.coords?.latitude}
                  onPress={() => {
                    window.open(
                      `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${item?.info?.location?.coords?.latitude},${item?.info?.location?.coords?.longitude}`
                    );
                  }}
                  style={{
                    width: "10%",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      width: 22,
                      height: 22,
                      tintColor: item?.info?.location?.coords?.latitude
                        ? "#df1f26"
                        : "#ccc",
                    }}
                    source={require("../assets/marker.png")}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => onClick(item)}
                  style={{
                    width: "10%",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{ width: 25, height: 25, tintColor: "#df1f26" }}
                    source={require("../assets/document.png")}
                  />
                </TouchableOpacity>
              </View>
            );
          }}
        />
      </View>
    </View>
  );
};
