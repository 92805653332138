import moment from "moment";
import { useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { apiservice } from "../service/service";
const { width } = Dimensions.get("screen");
import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoid29uZ3NhdG9ybiIsImEiOiJja2tkMDMxZnowNTAxMm9vZ21vbnE0YWNmIn0.2IjOp_X5pabJXKDN2Mw6sA",
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
  },
  menu: {
    width: width * 0.16,
    backgroundColor: "#fff",
    height: "100%",
  },
  maincontainer: {
    width: width * 0.84,
    backgroundColor: "#f5f5f9",
    padding: 25,
    height: "100%",
  },
  textInput: {
    height: 80,
    width:
      window.innerWidth > 500
        ? window.innerWidth * 0.2
        : window.innerWidth * 0.6,
    backgroundColor: "#fff",
    outlineColor: "transparent",
    outlineStyle: "none",
    borderWidth: 0.5,
    borderColor: "#707070",
  },
  textmenu: {
    fontFamily: "Prompt",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070",
  },
});

export const UserDetail = ({ detail }) => {
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);

  console.log("====================================");
  console.log(list1);
  console.log("====================================");

  useEffect(() => {
    trunkcallAPI();
  }, []);

  const trunkcallAPI = async () => {
    const res = await apiservice({
      path: "/mobile/getall",
    });
    const res1 = await apiservice({
      path: "/mobile/location/getall",
    });

    if (res.status == 200) {
      setList(res?.data);
      setList1(res1?.data?.data);
    }
  };

  console.log("====================================");
  console.log(list1);
  console.log("====================================");
  return (
    <View style={styles.maincontainer}>
      <link
        href="https://fonts.googleapis.com/css2?family=Prompt&display=swap"
        rel="stylesheet"
      />
      <Text style={{ fontFamily: "Prompt", fontSize: 32, fontWeight: "bold" }}>
        <Text style={{ fontFamily: "Prompt", fontSize: 30, color: "#ccc" }}>
          Web view / User /
        </Text>{" "}
        User Detail
      </Text>
      <View style={{ marginTop: 35 }}>
        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "bold",
            color: "#707070",
          }}
        >
          UID : {detail?.detail}
        </Text>
        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "normal",
            color: "#707070",
          }}
        >
          {detail?.info?.message}
        </Text>
        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "bold",
            color: "#707070",
            marginTop: 15,
          }}
        >
          Device Infomation{" "}
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: 14,
              fontWeight: "normal",
              color: "#707070",
            }}
          ></Text>
        </Text>

        <Text
          style={{
            fontFamily: "Prompt",
            fontSize: 14,
            fontWeight: "bold",
            color: "#707070",
            marginBottom: 30,
            marginTop: 30,
          }}
        >
          OS{" : "}
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: 14,
              fontWeight: "normal",
              color: "#707070",
            }}
          >
            {detail?.info?.OS || "-"}
          </Text>
          {"   "}Device Name{" : "}
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: 14,
              fontWeight: "normal",
              color: "#707070",
            }}
          >
            {detail?.info?.Device?.deviceName || "-"}
          </Text>
        </Text>

        <View
          style={[
            styles.textInput,
            {
              width: width * 0.8,
              height: width * 0.3,
              padding: 0,
              overflow: "hidden",
            },
          ]}
        >
          <Map
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: "100vh",
              width: "100vw",
            }}
            center={[100.614021, 13.668217]}
          >
            {list1
              ?.filter((w) => w?.Name == detail?.detail)
              ?.filter((e) => e?.location?.location?.coords)
              ?.map((e) => (
                <Marker
                  coordinates={[
                    e?.location?.location?.coords?.longitude,
                    e?.location?.location?.coords?.latitude,
                  ]}
                  anchor="bottom"
                >
                  <Image
                    style={{ width: 15, height: 15 }}
                    source={require("../assets/marker.png")}
                  />
                </Marker>
              ))}
          </Map>
        </View>

        <FlatList
          data={list?.filter(
            (e) =>
              e?.info?.item?.filter((el) => el == detail?.detail)?.length > 0
          )}
          ListHeaderComponent={
            <View
              style={{
                width: width * 0.8,
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                backgroundColor: "#DBDBDB",
              }}
            >
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                No.
              </Text>
              <Text
                style={{
                  width: "55%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Message
              </Text>

              <Text
                style={{
                  width: "25%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Receiver
              </Text>
            </View>
          }
          renderItem={({ item, index }) => {
            return (
              <View
                style={{
                  width: width * 0.8,
                  flexDirection: "row",
                  height: 40,
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
              >
                <Text
                  style={{
                    width: "10%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {index + 1}
                </Text>
                <Text
                  style={{
                    width: "55%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {item?.info?.message}
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {item?.info?.item?.length || 0}
                </Text>
              </View>
            );
          }}
        />
      </View>
    </View>
  );
};
