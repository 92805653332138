import moment from "moment";
import { useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { apiservice } from "../service/service";
const { width } = Dimensions.get("screen");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
  },
  menu: {
    width: width * 0.16,
    backgroundColor: "#fff",
    height: "100%",
  },
  maincontainer: {
    width: width * 0.84,
    backgroundColor: "#f5f5f9",
    padding: 25,
    height: "100%",
  },
  textInput: {
    height: 80,
    width:
      window.innerWidth > 500
        ? window.innerWidth * 0.2
        : window.innerWidth * 0.6,
    backgroundColor: "#fff",
    outlineColor: "transparent",
    outlineStyle: "none",
    borderWidth: 0.5,
    borderColor: "#707070",
    padding: 5,
  },
  textmenu: {
    fontFamily: "Prompt",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070",
  },
});

export const NotiContainer = ({ onClick, onClick1 }) => {
  const [state, setstate] = useState([]);
  const [is_group, setis_group] = useState(true);
  const [message, setmesssage] = useState({});

  useEffect(() => {
    CallAPI();
  }, []);

  const CallAPI = async () => {
    const res = await apiservice({
      path: "/mobile/getall",
    });

    if (res.status == 200) {
      setstate(res?.data);
    }
  };

  const sendNotification = async () => {
    let path = "/mobile/senduser?size=10";
    console.log(message);
    if (message?.message) {
      path = path + "&message=" + message?.message;
    }

    if (message?.uid) {
      path = path + "&uid=" + message?.uid;
    }

    const res = await apiservice({
      path,
    });

    if (res.status == 200) {
      onClick1();
      setmesssage({ message: "", uid: null });
      CallAPI();
    }
  };

  return (
    <View style={styles.maincontainer}>
      <link
        href="https://fonts.googleapis.com/css2?family=Prompt&display=swap"
        rel="stylesheet"
      />
      <Text style={{ fontFamily: "Prompt", fontSize: 32, fontWeight: "bold" }}>
        <Text style={{ fontFamily: "Prompt", fontSize: 30, color: "#ccc" }}>
          Web view /
        </Text>{" "}
        Notification
      </Text>
      <View style={{ marginTop: 35 }}>
        <TextInput
          onChangeText={(text) => {
            setmesssage((val) => ({ ...val, message: text }));
          }}
          multiline
          value={message?.message}
          style={styles.textInput}
        />
        {!is_group && (
          <Text
            style={{ fontFamily: "Prompt", fontSize: 18, fontWeight: "bold" }}
          >
            UID
          </Text>
        )}
        {!is_group && (
          <TextInput
            onChangeText={(text) => {
              setmesssage((val) => ({ ...val, uid: text }));
            }}
            value={message?.message}
            style={[styles.textInput, { height: 40 }]}
          />
        )}
        <TouchableOpacity
          onPress={() => {
            setmesssage((val) => ({ ...val, uid: null }));
            setis_group((val) => !val);
          }}
          style={[
            styles.textInput,
            {
              justifyContent: "center",
              paddingHorizontal: 20,
              height: 40,
              marginTop: 10,
            },
          ]}
        >
          <Text
            style={{ fontFamily: "Prompt", fontSize: 16, fontWeight: "bold" }}
          >
            {is_group ? "กลุุ่ม" : "บุคคล"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            sendNotification();
          }}
          style={[
            styles.textInput,
            {
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 20,
              height: 40,
              marginTop: 20,
              width: window.innerWidth * 0.1,
            },
          ]}
        >
          <Text
            style={{ fontFamily: "Prompt", fontSize: 16, fontWeight: "bold" }}
          >
            Send
          </Text>
        </TouchableOpacity>
        <View
          style={{
            marginVertical: 35,
            borderWidth: 0.5,
            borderColor: "#707070",
          }}
        />
        <Text
          style={{ fontFamily: "Prompt", fontSize: 26, fontWeight: "bold" }}
        >
          History
        </Text>
        <FlatList
          data={state}
          ListHeaderComponent={
            <View
              style={{
                width: width * 0.8,
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                backgroundColor: "#DBDBDB",
              }}
            >
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Date
              </Text>
              <Text
                style={{
                  width: "70%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Message
              </Text>
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Receiver
              </Text>
              <Text
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontFamily: "Prompt",
                  fontWeight: "Bold",
                  color: "#707070",
                }}
              >
                Detail
              </Text>
            </View>
          }
          renderItem={({ item, index }) => {
            return (
              <View
                style={{
                  width: width * 0.8,
                  flexDirection: "row",
                  height: 40,
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
              >
                <Text
                  style={{
                    width: "10%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {moment(item?.createdAt)?.format("DD/MM/YYYY")}
                </Text>
                <Text
                  style={{
                    width: "70%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {item?.info?.message}
                </Text>
                <Text
                  style={{
                    width: "10%",
                    textAlign: "center",
                    fontFamily: "Prompt",
                    fontWeight: "Bold",
                    color: "#707070",
                  }}
                >
                  {item?.info?.item?.length}
                </Text>
                <TouchableOpacity
                  onPress={() => onClick(item)}
                  style={{
                    width: "10%",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{ width: 25, height: 25, tintColor: "#df1f26" }}
                    source={require("../assets/document.png")}
                  />
                </TouchableOpacity>
              </View>
            );
          }}
        />
      </View>
    </View>
  );
};
