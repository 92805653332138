import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  View,
} from "react-native";
import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { MapDetail } from "./component/Map";
import { NotiContainer } from "./component/Noti";
import { NotiDetail } from "./component/NotiDetail";
import { UserDetail } from "./component/Userdetail";

import { MainContainer } from "./component/URL";
import { User } from "./component/User";
const { width } = Dimensions.get("screen");
import Modal from "react-modal";
import { apiservice } from "./service/service";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AnyReactComponent = ({ text }) => (
  <Image source={require("./assets/marker.png")} />
);

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoid29uZ3NhdG9ybiIsImEiOiJja2tkMDMxZnowNTAxMm9vZ21vbnE0YWNmIn0.2IjOp_X5pabJXKDN2Mw6sA",
});

const defaultProps = {
  center: {
    lat: 13.668217,
    lng: 100.614021,
  },
  zoom: 11,
};

export default function App() {
  const [state, setstate] = useState(0);
  const [detail, setDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [message, setmesssage] = useState({});
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [marke, setmark] = useState([100.614021, 13.668217]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <View style={styles.container}>
      <link
        href="https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css"
        rel="stylesheet"
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <View style={{ width: width * 0.2, alignItems: "center" }}>
          <Image
            style={{ width: width * 0.1, height: width * 0.1 }}
            source={require("./assets/icon.png")}
          />
          <Text
            style={{ fontFamily: "Prompt", fontSize: 16, fontWeight: "bold" }}
          >
            {"อัพโหลดข้อมูลสำเร็จ"}
          </Text>

          <TouchableOpacity
            onPress={async () => {
              // const res = await apiservice({
              //   path: "/mobile/location/create",
              //   method: "post",
              //   body: {
              //     Name: name,
              //     Address: address,
              //     location: marke,
              //     status: true,
              //   },
              // });

              // if (res?.status == 200) {
              //   closeModal();
              // }
              closeModal();
            }}
            style={[
              styles.textInput,
              {
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 20,
                height: 40,
                marginTop: 20,
                width: window.innerWidth * 0.1,
                backgroundColor: "#df1f26",
                borderRadius: 10,
              },
            ]}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: 16,
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              Confirm
            </Text>
          </TouchableOpacity>
        </View>
      </Modal>
      <Menu page={state} onClick={(e) => setstate(e)} />
      {/* Main */}
      {state == 0 && <MainContainer onClick={openModal} />}
      {state == 1 && (
        <NotiContainer
          onClick={(e) => {
            setDetail(e);
            setstate(5);
          }}
          onClick1={openModal}
        />
      )}
      {state == 2 && (
        <User
          onClick={(e) => {
            setDetail(e);
            setstate(6);
          }}
        />
      )}
      {state == 3 && (
        <MapDetail
          onLocation={() => {
            openModal();
          }}
        />
      )}

      {/* Sub */}
      {state == 5 && (
        <NotiDetail
          onClick={(e) => {
            setDetail(e);
            setstate(6);
          }}
          detail={detail}
        />
      )}
      {state == 6 && <UserDetail detail={detail} />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
  },
  menu: {
    width: width * 0.2,
    backgroundColor: "#fff",
    height: "100%",
  },
  maincontainer: {
    width: width * 0.84,

    padding: 25,
    height: "100%",
  },
  textInput: {
    height: 40,
    width:
      window.innerWidth > 500
        ? window.innerWidth * 0.2
        : window.innerWidth * 0.6,
    backgroundColor: "#fff",
    outlineColor: "transparent",
    outlineStyle: "none",
    borderWidth: 0.5,
    borderColor: "#707070",
    fontFamily: "Prompt",
    padding: 8,
  },
  textmenu: {
    fontFamily: "Prompt",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070",
  },
});

export const Menu = ({ onClick, page }) => {
  return (
    <View style={styles.menu}>
      <TouchableOpacity disabled style={{ marginTop: 30, marginLeft: 30 }}>
        <Image
          resizeMode="cover"
          style={{ width: width * 0.12, height: width * 0.04 }}
          source={require("./assets/icon.png")}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          onClick(0);
        }}
        style={{
          marginTop: 10,
          marginLeft: 15,
          padding: 15,
          borderRadius: 10,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: page == 0 ? "rgba(223, 31, 38, 0.16)" : "#fff",
        }}
      >
        <Image
          style={{
            width: 25,
            height: 25,
            marginRight: 10,
            tintColor: page == 0 ? "#df1f26" : "#707070",
          }}
          source={require("./assets/worldwide.png")}
        />
        <Text
          style={[
            styles.textmenu,
            {
              color: page == 0 ? "#df1f26" : "#707070",
            },
          ]}
        >
          Web View URL
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          onClick(1);
        }}
        style={{
          marginTop: 10,
          marginLeft: 15,
          padding: 15,
          borderRadius: 10,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: page == 1 ? "rgba(223, 31, 38, 0.16)" : "#fff",
        }}
      >
        <Image
          style={{
            width: 25,
            height: 25,
            marginRight: 10,
            tintColor: page == 1 ? "#df1f26" : "#707070",
          }}
          source={require("./assets/notification.png")}
        />
        <Text
          style={[
            styles.textmenu,
            {
              color: page == 1 ? "#df1f26" : "#707070",
            },
          ]}
        >
          Notification
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          onClick(2);
        }}
        style={{
          marginTop: 10,
          marginLeft: 15,
          padding: 15,
          borderRadius: 10,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: page == 2 ? "rgba(223, 31, 38, 0.16)" : "#fff",
        }}
      >
        <Image
          style={{
            width: 25,
            height: 25,
            marginRight: 10,
            tintColor: page == 2 ? "#df1f26" : "#707070",
          }}
          source={require("./assets/user.png")}
        />
        <Text
          style={[
            styles.textmenu,
            {
              color: page == 2 ? "#df1f26" : "#707070",
            },
          ]}
        >
          User
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          onClick(3);
        }}
        style={{
          marginTop: 10,
          marginLeft: 15,
          padding: 15,
          borderRadius: 10,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: page == 3 ? "rgba(223, 31, 38, 0.16)" : "#fff",
        }}
      >
        <Image
          style={{
            width: 25,
            height: 25,
            marginRight: 10,
            tintColor: page == 3 ? "#df1f26" : "#707070",
          }}
          source={require("./assets/map.png")}
        />
        <Text
          style={[
            styles.textmenu,
            {
              color: page == 3 ? "#df1f26" : "#707070",
            },
          ]}
        >
          Map Timeline
        </Text>
      </TouchableOpacity>
    </View>
  );
};
